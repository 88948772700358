import React from "react"
import { Link } from "gatsby"

const Navigation = ({ parents, staff, logout }) => {
  return (
    <>
      <div id="sub-header" className="bg-contrast-lower">
        <div className="container max-width-adaptive-lg">
          <div className="subnav subnav--expanded@sm js-subnav">
            <button className="reset btn btn--subtle margin-y-sm subnav__control js-subnav__control">
              <span>Show Submenu</span>
              <svg
                className="icon icon--xxs margin-left-xxs"
                aria-hidden="true"
                viewBox="0 0 12 12"
              >
                <polyline
                  points="0.5 3.5 6 9.5 11.5 3.5"
                  fill="none"
                  strokeWidth="1"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></polyline>
              </svg>
            </button>

            <div className="subnav__wrapper js-subnav__wrapper">
              <nav className="subnav__nav">
                <button
                  className="reset subnav__close-btn js-subnav__close-btn js-tab-focus"
                  aria-label="Close navigation"
                >
                  <svg className="icon" viewBox="0 0 16 16">
                    <g
                      strokeWidth="1"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                    >
                      <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                      <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                    </g>
                  </svg>
                </button>

                <ul className="subnav__list">
                  {parents !== null && (
                    <li className="subnav__item">
                      <Link className="subnav__link" to="/protected/parents">
                        {parents.title}
                      </Link>
                    </li>
                  )}
                  {staff !== null && (
                    <li className="subnav__item">
                      <Link className="subnav__link" to="/protected/staff">
                        {staff.title}
                      </Link>
                    </li>
                  )}
                </ul>
                <button className="reset cursor-pointer" onClick={logout}>
                  <div className="text-sm padding-y-xs padding-x-md border border-3 border-contrast-lower flex items-center color-error">
                    <svg
                      x="0px"
                      y="0px"
                      width="16px"
                      height="16px"
                      viewBox="0 0 16 16"
                    >
                      <g transform="translate(0, 0)">
                        <line
                          fill="none"
                          stroke="var(--color-error)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          x1="5.5"
                          y1="8.5"
                          x2="15.5"
                          y2="8.5"
                          data-color="color-2"
                        ></line>{" "}
                        <polyline
                          fill="none"
                          stroke="var(--color-error)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          points="11.5,4.5 15.5,8.5 11.5,12.5 "
                          data-color="color-2"
                        ></polyline>{" "}
                        <path
                          fill="none"
                          stroke="var(--color-error)"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          d="M8.5,15.5h-7 c-0.552,0-1-0.448-1-1v-12c0-0.552,0.448-1,1-1h7"
                        ></path>
                      </g>
                    </svg>
                    <span className="margin-left-xxs">Logout</span>
                  </div>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navigation
