import React, { useEffect, useState, useCallback } from "react"
import { navigate } from "gatsby"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Dashboard from "../components/protected/Dashboard"
import Navigation from "../components/protected/Navigation"
import useAuth from "../hooks/useAuth"
import LoginLoader from "../components/protected/login-loader"

import axios from "axios"

const Protected = ({ location }) => {
  const { isAuthenticated, state, logout } = useAuth()
  const redirect = location.pathname.split("/").pop()

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { state: { redirect } })
    }
  }, [isAuthenticated, state, redirect])

  const [parents, setParents] = useState([])

  const loadParents = useCallback(async () => {
    const token = state.jwt
    await axios
      .get(
        `${process.env.GATSBY_API_URL}/parents-page` ||
          `http://localhost:1337/parents-page`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(function (response) {
        // handle success
        setParents(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        setStaff(null)
      })
  }, [state])

  useEffect(() => {
    if (isAuthenticated) {
      loadParents()
    }
  }, [isAuthenticated, loadParents])

  const [staff, setStaff] = useState([])

  const loadStaff = useCallback(async () => {
    const token = state.jwt
    await axios
      .get(
        `${process.env.GATSBY_API_URL}/staff-page` ||
          `http://localhost:1337/staff-page`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(function (response) {
        // handle success
        setStaff(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
        setStaff(null)
      })
  }, [state])

  useEffect(() => {
    if (isAuthenticated) {
      loadStaff()
    }
  }, [isAuthenticated, loadStaff])

  return (
    <Layout>
      {!isAuthenticated && <LoginLoader></LoginLoader>}
      {isAuthenticated && (
        <>
          <Navigation parents={parents} staff={staff} logout={logout} />
          <Router basepath="/protected">
            <Dashboard path="parents" data={parents} default />
            <Dashboard path="staff" data={staff} />
          </Router>
        </>
      )}
    </Layout>
  )
}
export default Protected
