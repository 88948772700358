import React from "react"

const LoginLoader = () => {
  return (
    <div className="container max-width-lg text-center padding-y-xxxl">
      <div className="circle-loader circle-loader--v5" role="alert">
        <p className="circle-loader__label">Content is loading...</p>
        <div aria-hidden="true">
          <div className="circle-loader__shadow"></div>
          <div className="circle-loader__ball"></div>
        </div>
      </div>
    </div>
  )
}

export default LoginLoader
