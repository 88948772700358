import React from "react"

const Dashboard = ({ data }) => {
  function markupDataContent() {
    return { __html: data.content }
  }
  return (
    <>
      <div
        id="content"
        className="container max-width-adaptive-sm padding-top-xl padding-bottom-xxl position-relative z-index-1"
      >
        <article className="article text-component">
          <h1 className="text-xxxl">{data.title}</h1>
          <p className="text-md color-contrast-medium">{data.description}</p>
          <div dangerouslySetInnerHTML={markupDataContent()} />
        </article>
      </div>
    </>
  )
}

export default Dashboard
